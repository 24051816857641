import React from "react";
import { Formik, ErrorMessage, Field } from "formik";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import Select from "react-select";
import { FiTrash } from "react-icons/fi";
import { FaPlus } from "react-icons/fa";
import * as yup from "yup";
import moment from "moment";

import Modal from "../../../components/Modals";
import requests from "../../../services/requests";

const ModalDriverFreightTrajectory = ({ actionModal, openModal, handleSuccess, driverFreight, trajectories }) => {
    const typeOptions = [
        { value: "COLETA", label: "COLETA" },
        { value: "ENTREGA", label: "ENTREGA" },
    ];

    return (
        <Modal
            onClose={actionModal}
            size="xs:w-12/12 lg:w-6/12 min-h-40%"
            show={openModal}
            title={
                <div className="flex items-center">
                    <h5 className={`mr-6 text-roxo_oficial font-bold`}>Trechos de viagem</h5>
                </div>
            }
        >
            <>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        trajectories: trajectories,
                    }}
                    validationSchema={yup.object().shape({
                        trajectories: yup
                            .array()
                            .of(
                                yup.object().shape({
                                    type: yup.string().required("Campo obrigatório."),
                                    address: yup.string().required("Campo obrigatório."),
                                })
                            )
                            .required("Deve ter trajetos.")
                            .min(1, "Preencha no minímo um trajeto."),
                    })}
                    onSubmit={(values, { setFieldError, setSubmitting }) => {
                        setSubmitting(true);

                        let data = {
                            vehicle: driverFreight.vehicle.identifier,
                            driver: driverFreight.userDriverIdentifier,
                            startsAt: driverFreight.startsAt
                                ? moment(driverFreight.startsAt).format("DD/MM/yyyy HH:mm:ss")
                                : null,
                            endsAt: driverFreight.endsAt ? moment(driverFreight.endsAt).format("DD/MM/yyyy HH:mm:ss") : null,
                            destinationIn: driverFreight.destinationIn,
                            trajectories: values.trajectories,
                        };

                        requests
                            .editDriverFreight(driverFreight.identifier, data)
                            .then(response => {
                                setSubmitting(false);
                                handleSuccess();
                                toast.success("Trecho editado com sucesso!");
                            })
                            .catch(error => {
                                setSubmitting(false);
                            });
                    }}
                >
                    {({ setFieldValue, handleChange, handleBlur, handleSubmit, isSubmitting, values }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="w-full">
                                <div className="flex mt-4">
                                    <div className="w-6/12">
                                        <div className="flex justify-between mt-4">
                                            {`${
                                                values.trajectories.length === 0
                                                    ? "Necessário preencher pelo menos um trecho"
                                                    : `${values.trajectories.length} trecho${
                                                          values.trajectories.length > 1 ? "s" : ""
                                                      } inserido${values.trajectories.length > 1 ? "s" : ""}`
                                            }`}
                                        </div>
                                    </div>
                                    <div className="w-6/12">
                                        <div className="mt-4 float-right">
                                            <label>
                                                Adicionar novo trecho
                                                <button
                                                    className="cursor-pointer inline-block bg-green-400 p-1 rounded text-white ml-3 text-sm"
                                                    onClick={() => {
                                                        setFieldValue("trajectories", [
                                                            ...values.trajectories,
                                                            {
                                                                address: "",
                                                                type: "",
                                                            },
                                                        ]);
                                                    }}
                                                >
                                                    <FaPlus />
                                                </button>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="flex-wrap overflow-x-auto mt-5"
                                    style={values.trajectories.length > 5 ? { height: 300 } : {}}
                                >
                                    {values.trajectories.length > 0 &&
                                        values.trajectories.map((trajectory, index) => (
                                            <>
                                                <div className="flex mt-4">
                                                    <div className="w-4/12">
                                                        <label htmlFor="cteNumber" className="text-roxo_oficial font-bold">
                                                            TRECHO {index + 1} - TIPO
                                                        </label>
                                                        <button
                                                            className="ml-2"
                                                            onClick={e => {
                                                                setFieldValue(
                                                                    "trajectories",
                                                                    values.trajectories.filter((_, key) => key !== index)
                                                                );
                                                            }}
                                                        >
                                                            <FiTrash size={15} color="red" />
                                                        </button>
														<Select
                                                            name={`trajectories.${index}.type`}
                                                            className="block uppercase text-blueGray-600 text-xs font-bold"
															options={typeOptions}
															value={typeOptions.filter(
																option => option.value === trajectory?.type
															)}
                                                            onChange={option => {
                                                                values.trajectories[index].type = option.value;
                                                                setFieldValue("trajectories", values.trajectories);
                                                            }}
															placeholder=""
														/>
														<ErrorMessage
                                                            component="p"
                                                            className="mt-2 text-red mb-4 font-light text-xs"
                                                            name={`trajectories.${index}.type`}
                                                        />
                                                    </div>
                                                    <div className="w-8/12 ml-4">
                                                        <label htmlFor="driver" className="text-roxo_oficial font-bold">
                                                            Endereço
                                                        </label>
                                                        <Field
                                                            as="textarea"
                                                            rows="2"
                                                            className="w-full rounded-md border py-1 px-2 border-C2"
                                                            name={`trajectories.${index}.address`}
                                                            onChange={handleChange}
                                                            value={trajectory.address}
                                                            placeholder="Preencha o trecho inicial"
                                                        />
                                                        <ErrorMessage
                                                            component="p"
                                                            className="text-red font-light text-xs"
                                                            name={`trajectories.${index}.address`}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        ))}
                                </div>
                                <div className="flex mt-4">
                                    <div className="w-6/12">
                                        <label htmlFor="driver" className="text-roxo_oficial font-bold">
                                            Trecho inicial
                                        </label>
                                        <Field
                                            as="textarea"
                                            rows="2"
                                            disabled
                                            className="w-full rounded-md border py-1 px-2 border-C2"
                                            onChange={handleChange}
                                            value={values.trajectories[0]?.address}
                                            placeholder="Preenchido automaticamente"
                                        />
                                    </div>
                                    <div className="w-6/12 ml-4">
                                        <label htmlFor="vehicle" className="text-roxo_oficial font-bold">
                                            Trecho final
                                        </label>
                                        <Field
                                            as="textarea"
                                            rows="2"
                                            disabled
                                            className="w-full rounded-md border py-1 px-2 border-C2"
                                            onChange={handleChange}
                                            value={values.trajectories.slice(-1)[0]?.address}
                                            placeholder="Preenchido automaticamente"
                                        />
                                    </div>
                                </div>
                            </div>
                            <button
                                className="bg-roxo_oficial text-white py-2 px-5 rounded-md float-right mt-10 mb-10"
                                type="submit"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? (
                                    <>
                                        Salvando <ClipLoader size={10} loading={isSubmitting} />
                                    </>
                                ) : (
                                    <>Salvar</>
                                )}
                            </button>
                        </form>
                    )}
                </Formik>
            </>
        </Modal>
    );
};

export default ModalDriverFreightTrajectory;
